import Navbar from "../../components/navbar/Navbar";
import Featured from "../../components/featured/Featured";
import "./home.scss";
import List from "../../components/list/List";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import Membership from "../membership/Membership";

const Home = ({type}) => {
  const [lists, setLists] = useState([]);
  const [canales, setCanales] = useState([]);
  const [estadom, setEstadom] = useState(true);

  const {id} = useParams();
  
   
  useEffect(() => {
    const getRandomLists = async () => {
    var urlcore = localStorage.getItem("url")

    if(id === 0 || id === undefined){
      var token2 = localStorage.getItem("user").replace(/["]+/g, '');
      try {
        const res = await axios.get(`${urlcore}/channels/`,{
            headers: {
              'Authorization':
              `Bearer ${token2}`
            },
          }
        );
        //console.log("channels");
        //console.log(res.data.channels);

      var canalesfiltro=[];
        for(var i=0;i<res.data.channels.length;i++){

          if(res.data.channels[i].flag_web){
          canalesfiltro.push(res.data.channels[i])    
          }
        }
    
        //console.log(res.data.categories);
        //console.log(res.data.channels[0].flag_web);
        setLists(res.data.categories);
        setCanales(canalesfiltro);
      } catch (err) {
        console.log(err);
      }
      
    }else{
      var token2 = localStorage.getItem("user").replace(/["]+/g, '');
      try {
        const categoriacanal = await axios.get(`${urlcore}/channels/?category=${id}`,{
            headers: {
              'Authorization':
              `Bearer ${token2}`
            },
          }
        );
        //console.log("categoriascanal7");
        //console.log(categoriacanal.data);
        
        var canalesfiltro=[];
        for(var i=0;i<categoriacanal.data.channels.length;i++){
          if(categoriacanal.data.channels[i].flag_web){
          canalesfiltro.push(categoriacanal.data.channels[i])    
          }
        }

        setLists(categoriacanal.data.categories);
        setCanales(canalesfiltro);

      } catch (err) {
        console.log(err);
      }
    }

    var token3=localStorage.getItem("user").replace(/["]+/g, '');
      
    //console.log("url consulta: ",urlcore);
    if(urlcore === "https://metrowancore.nextwarela.com"){
      try {
        const fecha = await axios.get(`${urlcore}/user/me/`,{
          headers: {
            'Authorization':
            `Bearer ${token3}`
          },
        });

        //Obtenemos la fecha de expiración de la Membresía
        const fechaDataMembresia = fecha.data.data.activate;
        // Verificamos si existe la data de fecha de membresia es distinto a null 
        if(fechaDataMembresia != null){

          // Se convierte a objeto fecha y se le da hora de expiración las 23:59:59
          // Esto evita errores por cambio de zona horaria con el servidor
          const fechaMembresia = new Date(fechaDataMembresia+'T23:59:59');
          //console.log("Fecha Membresía format:", fechaMembresia);

          // Se obtiene la fecha y hora actuales de la máquina del cliente
          var fechaHoy = new Date();

          if(fechaMembresia >= fechaHoy){
            // Si la fecha de membresia está activa se permite la conexión
            setEstadom(true);
            //console.log("tiene membresia activa");
          }else{
            // Si la fecha de membresia expiró se rechaza la conexión
            setEstadom(false);
            //console.log("no tiene memebresia activa");
          }
        }else{
          // Si la data de fecha de membresia es NULL se rechaza la conexión
          setEstadom(false);
        }
      } catch (err) {
        console.log(err);
      }
    }else{
      setEstadom(true);
    }
      
    };
    getRandomLists();

  }, [id]);

  return (

    estadom===true?


    <div className="home">
      <Navbar />
      <Featured type={type} cate={lists}/>
          <List list={lists} canales={canales} cate={id}></List>
      
      <br></br>
    </div>
    :
    <div>
      <Navbar />
    <Membership/>
    </div>
    
  );
};

export default Home;
