import { loginFailure, loginStart, loginSuccess } from "./AuthActions";

export const Timeout = (time) => {
	let controller = new AbortController();
	setTimeout(() => controller.abort(), time * 1000);
	return controller;
};

export const login = async (user, dispatch) => {

  //console.log(user);
  dispatch(loginStart("validando ..."));
   

    await fetch("https://webinterna.com/auth/login/",{
      signal: Timeout(2).signal,
      method:"POST",
      body:JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response)=>{
      
      if (response.ok) {
        response.json().then((result)=>{
          dispatch(loginSuccess(result.access,"https://webinterna.com"));
          localStorage.setItem("url", "https://webinterna.com");

          console.warn("result",result.access);
          //localStorage.setItem("user", JSON.stringify(result.access));
        })        
      }else{
       response.json().then((result)=>{
        console.warn("resultaquiiiiiiiiiiiiiiiii",result.message);
        dispatch(loginFailure(result.message));
        })
      }

    }).catch((err) =>{localStorage.setItem("url", "https://metrowancore.nextwarela.com");});



    await fetch("https://metrowancore.nextwarela.com/auth/login/",{
      signal: Timeout(2).signal,
      method:"POST",
      body:JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response)=>{
      
      if (response.ok) {
      
        response.json().then((result)=>{
          dispatch(loginSuccess(result.access,"https://metrowancore.nextwarela.com"));
          console.log("tokennnnnnnnnnnn")

          console.warn("result",result.access);
          //localStorage.setItem("user", JSON.stringify(result.access));
        })
        
      }
      else{
        
        response.json().then((result)=>{
          console.warn("result",result.message);
          dispatch(loginFailure(result.message));
        })

      }

    }).catch((err) => console.log("eorrrrrrrrrrrrr"));


  };


  export const register = async (user, dispatch) => {
    dispatch(loginStart("validando ..."));
    
  
      await fetch("https://metrowancore.nextwarela.com/register/",{
        
        method:"POST",
        body:JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response)=>{
        console.log("rptaaaaaaaaaaa");
        if (response.ok) {
          response.json().then((result)=>{
            console.log("tokennnnnnnnnnnngaaaaaaaa")
            dispatch(loginFailure("Cuenta creada correctamente "));
            console.warn("result",result.access);
            //localStorage.setItem("user", JSON.stringify(result.access));
          })
          
        }
        else{
          response.json().then((result)=>{
            //console.warn("result",result.email);
            dispatch(loginFailure(result.email));
          })
  
        }
  
      }).catch((err) => console.log("error-112"));
  
  
    };


    export const recuperar = async (user, dispatch) => {
      console.log("recuperarrrrrrrrrr");
      console.log(user);
      dispatch(loginStart("validando ..."));
      
    
        await fetch("https://metrowancore.nextwarela.com/forgot/password/",{
          
          method:"POST",
          body:JSON.stringify(user),
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((response)=>{
          console.log("recuperar");
          if (response.ok) {
          
            response.json().then((result)=>{
              dispatch(loginFailure("Cuenta creada correctamente "));
              console.warn("result",result.status);
              //localStorage.setItem("user", JSON.stringify(result.access));
            })
            
          }
          else{
            
            
            response.json().then((result)=>{
              console.warn("result",result.email);
              dispatch(loginFailure(0));
            })
    
          }
    
        }).catch((err) => console.log("eorrrrrrrrrrrrr"));
    
    
      };
    