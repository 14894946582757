import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";
import { useRef, useState } from "react";
import ListItem from "../listItem/ListItem";
import "./list.scss";

export default function List({ list,canales ,cate }) {
  const [isMoved, setIsMoved] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const [titulo, setTitulo] = useState();

  const listRef = useRef();
  var demo=[1,2,3,4,5,6]
  var vacio={
    "id": 18,
    "name": "Pronto mas canales",
    "number": "xxxx",
    "stream": "",
    "image": "",
    "description": "Pronto más canales para ti",
    "category": {
      "id": 8,
      "name": "Musica",
      "order": 2,
      "image": null,
      "flag_adult": false,
      "flag_available": true,
      "created_at": "2021-08-04 11:16:48",
      "updated_at": "2021-08-04 11:16:48"
    },
    "category__id": "8",
    "category__name": "Pronto",
    "programs": [],
    "created_at": "2021-08-04 22:52:29",
    "updated_at": "2021-11-02 11:31:20"
  }

  const handleClick = (direction) => {
    setIsMoved(true);
    let distance = listRef.current.getBoundingClientRect().x -50 ;
    console.log("distancia");
    console.log(window.innerWidth);
    console.log((window.innerWidth-50)/240);
    console.log(distance);
    console.log(canales.length)

    if (direction === "left" && slideNumber > 0) {
      setSlideNumber(slideNumber - 1);
      listRef.current.style.transform = `translateX(${240 + distance}px)`;
    }
    if (direction === "right" && slideNumber < canales.length-Math.round(((window.innerWidth-50)/240))) {
      setSlideNumber(slideNumber + 1);
      listRef.current.style.transform = `translateX(${-240 + distance}px)`;
      setTimeout(() => {
      }, 2000);
    }
  };
  return (
    <div className="list">
      <span className="listTitle">{list.name}</span>
      <div className="wrapper">
        <ArrowBackIosOutlined
          className="sliderArrow left"
          onClick={() => handleClick("left")}
          style={{ display: !isMoved && "none" }}
        />
        <div className="container" ref={listRef}>
        
      {
        canales.length===0?
        demo.map((item,i) => (
          <ListItem key={i} item={vacio}/>

        ))
        
        :
        canales.map((item,i) => (
          <ListItem key={i} item={item} index={i} catego={cate}/>

        ))
      }
      
        </div>
        <ArrowForwardIosOutlined
          className="sliderArrow right"
          onClick={() => handleClick("right")

        }
          

        />
      </div>
    </div>
  );
}
