export const loginStart = (msg) => ({
  type: "LOGIN_START",
  error:msg
});
export const loginSuccess = (user,url) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
  ruta:url
});
export const loginFailure = (err) => ({
  type: "LOGIN_FAILURE",
  error:err
});

export const registerStart = (msg) => ({
  type: "REGISTER_START",
  error:msg
});
export const registerSuccess = (user,url) => ({
  type: "REGISTER_SUCCESS",
  payload: user,
  ruta:url
});
export const registerFailure = (err) => ({
  type: "REGISTER_FAILURE",
  error:err
});

//logout

export const logout = () => ({
  type: "LOGOUT",
});
