import "./listItem.scss";
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import {PlayArrow} from "@material-ui/icons";
import { useState } from "react";

export default function ListItem({ index, item , catego }) {
  const [isHovered, setIsHovered] = useState(false);
  const trailer =
    "https://player.vimeo.com/external/371433846.sd.mp4?s=236da2f3c0fd273d2c6d9a064f3ae35579b2bbdf&profile_id=139&oauth2_token_id=57447761";
    return (
    
    <Link to ={{ pathname:`/watch/${index}-${catego===undefined?0:catego}`,movie:item}}>
    

    <div
      className="listItem"
      style={{ left: false }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={item.image}
        alt=""
      />
      {isHovered && (
      
          <div className="itemInfo">
            
            <ReactPlayer className='prueba'
          // This is the video address passed from the superior page
          url={item.stream}
          playing={true}
          muted={true}
          height='60%'
          width='100%' 
          controls={true}
          config={{
            file: {
              forceHLS: true,
            }
          }}
          />

            <div className="icons">
              <PlayArrow className="icon" />
            </div>
            <div className="itemInfoTop">
              <span>{item.name}</span>
              <span className="limit">{item.number}</span>
              
            </div>
            <div className="desc">
              {item.description}
            </div>
            <div className="genre">{item.category__name}</div>
          </div>
        
      )}
    </div>
    </Link>
  );
}
