import { ArrowBackOutlined } from "@material-ui/icons";
import { useState, useEffect } from "react";
import "./watch.scss";
import ReactPlayer from 'react-player'
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import Lista from "../../components/lista/Lista";

export default function Watch() {

  const [player, setplayer] = useState(false);
  const [canales, setCanales] = useState();
  const [lista, setList] = useState();
  const [actual, setActual] = useState();
  const [loading, setLoading] = useState(true);
  
  const location = useLocation();
  const {cn} = useParams();
  var data = cn.split("-");

  // console.log("idddddddddddddddddd");
  // console.log(data[0]);
  // console.log(data[1]);

  

  useEffect(() => {

    const getRandomLists = async () => {
      // console.log("efectttttttttttttttttttttttttt")

      setLoading(true);

      if(data[1] === 0 || data[1] === undefined)
      {
        // console.log("111111111111111111111111111111111111");
        var token2 = localStorage.getItem("user").replace(/["]+/g, '');

        try {
          const res = await axios.get(
            'https://metrowancore.nextwarela.com/channels/',
            {
              headers: {
                'Authorization':
                `Bearer ${token2}`
              },
            }
          );

          var canalesfiltro = [];
          for(var i=0; i<res.data.channels.length; i++){

           if(res.data.channels[i].flag_web){
            canalesfiltro.push(res.data.channels[i])    
           }
          }
      
          
          // console.log("channels1111111111111111");
          setCanales(canalesfiltro);
          setList(res.data.categories);
          // console.log(res.data.channels);
          

        } catch (err) {
          // console.log(err);
        }
        setLoading(false);
        
      }
      else{
        setLoading(true);
        // console.log("22222222222222222222222222222222222222222")
        var token3 = localStorage.getItem("user").replace(/["]+/g, '');
        try {
          const categoriacanal = await axios.get(
            `https://metrowancore.nextwarela.com/channels/?category=${data[1]}`,
            {
              headers: {
                'Authorization':
                `Bearer ${token3}`
              },
            }
          );
          // console.log("categoriascanal7");
          
          var canalesfiltro2 = [];
          for(var d=0; d<categoriacanal.data.channels.length; d++){

           if(categoriacanal.data.channels[d].flag_web){
            canalesfiltro2.push(categoriacanal.data.channels[d])    
           }
          }
        
          setCanales(canalesfiltro2);
          setList(categoriacanal.data.categories);
          // console.log(categoriacanal.data.channels);
          
        

        } catch (err) {
          // console.log(err);
        }
        setLoading(false);
  
      }
      
      
      
    };
    getRandomLists();

  }, []);

  return (
    <div>
    {loading && <div><h1>Loading</h1></div>}
    {!loading && (
    
    <div className="watch">
     
     <h1 className="volver">Volver</h1>
      
      <ReactPlayer className='player'
          // This is the video address passed from the superior page
          url={canales[data[0]].stream}
          playing={true}
          loop={false}
          muted={true}
          height='100%'
          width='100%' 
          controls={true}
          config={{
            file: {
              forceHLS: true,
            }
          }}
    />
          <Lista list={lista} canales={canales} cate={data[1]}></Lista>

          <Link to = "/">
          
      <div className="back">
        <ArrowBackOutlined>
        </ArrowBackOutlined>

      </div>
      </Link>

    </div>
    )}
    </div>
  )

}
